import React from 'react';

import Layout from '@layouts/Layout';

import BespokeCallToAction from '@components/call-to-actions/BespokeCallToAction';
import ModularCallToAction from '@components/call-to-actions/ModularCallToAction';
import QuoteBlock from '@components/_ui/QuoteBlock';
import TestimonialCard from '@components/_ui/TestimonialCard';

const Showcase = () => {
    const testimonials = [
        {
            quote: 'Great pergola – fills up the blank wall space and makes the most of the outdoor space. Very pleased!',
            author: 'Mr G Knapp, Sutton Coalfield',
            imageUrl: '/img/showcase/mod2-pergola-against-house.jpg',
            imageAlt: 'An example of module 2, built against a brick house, and surrounded by a barbeque, wicker furniture, and potted flowers'
        },
        {
            quote: "We have a pergola supplied by Perfect Pergola's in our garden - it is looking great with the roses climbing up it.",
            author: 'Caroline Tatham – Principle, Cotswold Gardening School',
            imageUrl: '/img/showcase/landscape-one.jpg',
            imageAlt: 'A medium-sized lean-to pergola over some decking, strung with decorative lights and surrounded by assorted potted flowers.'
        },
    ];

    return (
        <Layout metaData={{ title: 'Customer Showcase' }}>
            <header className="hero">
                <div className="container flow--large">
                    <h1 className="text-page-title">Customer Showcase</h1>
                    <div className="flow">
                        <span><strong>Pergola</strong>&nbsp;[per-go-la]</span>
                        <p className="text-paragraph">derived from the latin ‘Pergula’ and first used in the C17th to describe the traditional masonry columns of the Italian Renaissance period.</p>
                    </div>
                </div>
            </header>
            <section className="wrapper background-color--chalk">
                <div className="container flow">
                    <div className="showcase" style={{ '--rows': 1 }}>
                        <div className="showcase__item" style={{ '--col-span': 4, '--row-span': 1 }}>
                            <img src="/img/showcase/square-three.jpg" alt="A 3D CAD drawing of a bespoke pergola plan" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 4, '--row-span': 1 }}>
                            <img src="/img/showcase/portrait-one.jpg" alt="A customer example of module 6, built on a raised tiled dias" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 4, '--row-span': 1 }}>
                            <img src="/img/showcase/square-four.jpg" alt="A close up of a lean-to pergola, with hills in the distance" />
                        </div>
                    </div>
                    <TestimonialCard data={testimonials[0]} />
                    <div className="showcase" style={{ '--rows': 5 }}>
                        <div className="showcase__item" style={{ '--col-span': 5, '--row-span': 3 }}>
                            <img src="/img/showcase/portrait-two.jpg" alt="A small standalone pergola which a customer has built over some steps leading down to their driveway" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 7, '--row-span': 2 }}>
                            <img src="/img/showcase/square-seven.jpg" alt="A lean-to pergola built against a customers house, and stained with a dark wood stained" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 7, '--row-span': 3 }}>
                            <img src="/img/showcase/square-five.jpg" alt="A freshly installed leon-to pergola over a customers patio" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 5, '--row-span': 2 }}>
                            <img src="/img/showcase/square-six.jpg" alt="An example of module 6, under construction in an open area" />
                        </div>
                    </div>
                    <TestimonialCard data={testimonials[1]} />
                    <div className="showcase" style={{ '--rows': 2 }}>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 2 }}>
                            <img src="/img/showcase/square-one.jpg" alt="A leon to pergola set against the side of a stone-built house" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 2 }}>
                            <img src="/img/showcase/square-two.jpg" alt="A pergola constructed in front of a small treeline, stained with a walnut-brown wood stain" />
                        </div>
                    </div>

                    <div className="showcase" style={{ '--rows': 2 }}>
                        <div style={{ '--col-span': 6, '--row-span': 2 }} className="showcase__item">
                            <img src="/img/showcase/mod5-raised.jpg" className="img--fullwidth display--block" alt="An example of module 5; a double length standalone pergola built on a raised patio, covering some wicker garden furniture" />
                        </div>
                        <div style={{ '--col-span': 6, '--row-span': 2 }} className="showcase__item">
                            <img src="/img/showcase/mod5-stained-over-patio.jpg" className="img--fullwidth display--block" alt="An example of module 5; a double length standalone pergola built on a patio, covering some wicker garden furniture" />
                        </div>
                    </div>
                    <div className="showcase" style={{ '--rows': 1 }}>
                        <div style={{ '--col-span': 6, '--row-span': 1 }} className="showcase__item"><img src="/img/showcase/pergola-against-house.jpg" className="img--fullwidth display--block" alt="Example of a pergola built on a patio up against a house" /></div>
                        <div style={{ '--col-span': 6, '--row-span': 1 }} className="showcase__item"><img src="/img/showcase/oak-pergola.png" className="img--fullwidth display--block" alt="Oak Pergola on a raised patio stand-alone" /></div>
                    </div>

                    <div className="showcase" style={{ '--rows': 2 }}>
                        <div style={{ '--col-span': 6, '--row-span': 2 }} className="showcase__item"><img src="/img/showcase/plymouth-pergolas.jpg" className="img--fullwidth display--block" alt="Two pergolas in the countryside" /></div>
                        <div style={{ '--col-span': 6, '--row-span': 2 }} className="showcase__item"><img src="/img/showcase/pergola-web.jpg" className="img--fullwidth display--block" alt="" /></div>
                    </div>

                    <div className="showcase" style={{ '--rows': 2 }}>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 2 }}>
                            <img src="/img/showcase/jo-pergola.jpg" alt="A stand alone pergola with dark stain adjacent to customer's house and conservatory" />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 2 }}>
                            <img src="/img/showcase/mod5-oak.jpg" alt="A module 5 pergola in oak, constructed on decking for a unique outdoor entertaining space" />
                        </div>
                    </div>


                    <div className="showcase" style={{ '--rows': 4 }}>
                        <div className="showcase__item" style={{ '--col-span': 8, '--row-span': 2 }}>
                            <img src="/img/showcase/pergola-night.jpg" alt="An example of a pergola, in the evening with lanterns and lights with a dining table underneath it." />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 4, '--row-span': 2 }}>
                            <img src="/img/showcase/pergola-kitchen.jpg" alt="An example of a pergola with a kitchen area under it with seating." />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 2 }}>
                            <img src="/img/showcase/pergola-kitchen-at-night.jpg" alt="An example of a pergola with a kitchen area under it with seating at night." />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 1 }}>
                            <img src="/img/showcase/20mtr-pergola-2.jpg" alt="An example of 20 meter pergola, and surrounded by a view in the country side." />
                        </div>
                        <div className="showcase__item" style={{ '--col-span': 6, '--row-span': 1 }}>
                            <img src="/img/showcase/mod2-24hr-hpt.jpg" alt="A module 2 pergola, constructed on a pateo built up against a brick house for a unique outdoor entertaining space." />
                        </div>
                    </div>


                </div>
            </section>
            <section className="background-color--chalk">
                <div className="container container--xsmall">
                    <QuoteBlock />
                </div>
            </section>
            <section className="wrapper backdrop backdrop--top-half">
                <div className="container">
                    <div className="grid-x grid-margin-x grid-margin-y">
                        <div className="cell medium-6">
                            <ModularCallToAction />
                        </div>
                        <div className="cell medium-6">
                            <BespokeCallToAction />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Showcase;
